import { Injectable } from '@angular/core';
import { BaseService } from '../../core/services/base.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HandleError } from '../../core/helpers/error-handler';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  urlAPI = environment.apiUrl;

  constructor(private _base: BaseService, private _http: HttpClient, private _handler: HandleError,) { }

  getAppInfo(): Observable<any> {
    const url = `https://play.google.com/store/apps/details?id=com.beverlyboy.crewsnow.android&hl=en&callback=ng_jsonp_callback_1`;
    return this._http.jsonp(url, 'callback');
    // return this._base.get(url);
  }

  getMacAppInfo() {
    // const url = `http://itunes.apple.com/lookup?bundleId=com.co.jobspace`;
    // return this._base.get(url);
    const url = "https://itunes.apple.com/lookup?bundleId=com.co.jobspace&jsoncallback=callback";
    return this._http.jsonp(url, 'callback');
  }

}
