import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './auth/services/author-guard.service';
import { AuthService } from './auth/services/auth.service';
import { BaseService } from '@core/services/base.service';
import { HandleError } from './core/helpers/error-handler';
import { Helpers } from './core/helpers/helper';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { SharedModule } from '@share/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MessagingService } from '@core/services/messaging.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from '@env/environment';
//import { environment } from '../environments/environment'; 
import {firebase} from '../environments/firebase';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    SnotifyModule,
    SharedModule,
    BrowserAnimationsModule,
    NgbModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(firebase),
	
  ],
  providers: [
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    AuthService,
    BaseService,
    AuthGuard,
    HandleError,
    Helpers,
    SnotifyService,
    MessagingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
