<div *ngIf="key">
  <div *ngIf="key.errors">
    <div *ngIf="key['errors'].required" class="text-danger">{{name || "This field"}} is required!</div>
    <div *ngIf="key['errors'].email" class="text-danger">{{name || "This field"}} is invalid!</div>
    <div *ngIf="key['errors'].pattern" class="text-danger">{{name || "This field"}} is invalid!</div>
    <div *ngIf="key['errors'].minlength" class="text-danger">{{name}} at least is {{minlength}} character.</div>
    <div *ngIf="key['errors'].maxlength" class="text-danger">{{name}} is not more than {{maxlength}} characters.</div>
    <div *ngIf="key['errors'].whitespace" class="text-danger">Not allow space.</div>
    <!-- <div>{{key.errors | json}}</div> -->
  </div>
</div>