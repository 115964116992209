import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
// import { NotificationService } from '@module/private/notification/services/notification.service';
import { AuthService } from '../../auth/services/auth.service';
import { SnotifyService } from 'ng-snotify';
import { PublicService } from '../../public/public.service';
import { Router } from '@angular/router';
declare var $;

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private _snotify: SnotifyService,
    private _auth: AuthService,
    private _serviceTopbar: PublicService,
    public _router: Router, 
    ) {
    // this.angularFireMessaging.messaging.subscribe(
    //   (_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }, error => {
    //     console.log(error)
    //   }
    // );
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    })
  }

  

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    // this.angularFireAuth.authState.pipe(take(1)).subscribe(
    //   () => {
    //     const data = {};
    //     data[userId] = token;
    //     this.angularFireDB.object('fcmTokens/').update(data);
    //     this.receiveMessage();
    //   });
    if(token) {
      const body = {firebase_registration_id: token};
      this._auth.updateFcmId(body).subscribe(data => {
        console.log('update firebase success');
      }, error => {
        console.log(error)
      });
    }
    
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log("token firebase: "+ token)
        localStorage.setItem('notification', token);
        // this.updateToken(userId, token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    console.log('receiveMessage');
    this.angularFireMessaging.messages.subscribe(
      payload => {
        console.log('new message received. ', payload, this._router.url, this._router.url.indexOf('/chat'));
        // this.playAudioNoti();
        let button = [];
        if(this._router.url.indexOf('/chat') == -1) {
          this._snotify.info(payload['notification'].body, payload['notification'].title, {
            timeout: 5000,
            buttons: button
          });
        }
        this._serviceTopbar.firebaseNotice$.next(payload)
        this.currentMessage.next(payload);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      });
  }

  playAudioNoti() {
    const audio = new Audio();
    audio.src = '../../assets/sound/notifi_order.wav';
    audio.load();
    audio.play();
  }
}
