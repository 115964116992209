import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Observer } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { HandleError } from '@core/helpers/error-handler';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  urlAPI = environment.apiUrl;
  isLoggedIn = false;

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _handler: HandleError,
    ) {
    if (this.token) { this.isLoggedIn = true; }
  }
  get token() {
    return localStorage.getItem(environment.TOKEN) || null;
  }

  set token(token) {
    localStorage.setItem(environment.TOKEN, token);
  }

  get idUser() {
    return localStorage.getItem('idUser') || null;
  }

  set idUser(id) {
    localStorage.setItem('idUser', id);
  }

  login(data): Observable<any> {
    const url = `${this.urlAPI}/api/v1/user/login`;
    return this._http.post(url, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(
        map(res => {
          return res;
        }),
        tap(dt => {
          this.isLoggedIn = true;
          this.idUser = dt['data'].id;
          this.token =  dt['data'].token;
        }),
        catchError(error => this._handler.handle(error))
      );
  }

  logout(): void {
    this.isLoggedIn = false;
    localStorage.removeItem(environment.TOKEN);
    localStorage.removeItem('idUser');
    window.location.reload();
    // this._router.navigate(['/login'])
  }

  regiter(body): Observable<any> {
    const url = `${this.urlAPI}/api/v1/user/register`;
    const formData: FormData = new FormData();
   for(var item in body){
     formData.append(item, body[item]);
   }
   console.log(formData);
    return this._http.post(url, formData, { headers: new HttpHeaders() })
      .pipe(
        map(res => {
          return res;
        }),
        tap(dt => {
          console.log(dt);
        }),
        catchError(error => this._handler.handle(error))
      );
  }

// update field profile;
  update(body): Observable<any> {
    const url = `${this.urlAPI}/api/v1/user/update`;
    const formData: FormData = new FormData();
    for(var item in body){
      formData.append(item, body[item]);
    }
    return this._http.post(url, formData, { headers: new HttpHeaders({ "Authorization": "Bearer "+this.token }) })
      .pipe(
        map(res => {
          return res;
        }),
        tap(dt => {
          console.log(dt);
        }),
        catchError(error => this._handler.handle(error))
      );
  }


  // change password
  change_password(body): Observable<any> {
    const url = `${this.urlAPI}/api/v1/user/password`;
    return this._http.post(url, body, { headers: new HttpHeaders({ "Authorization": "Bearer "+this.token }) })
      .pipe(
        map(res => {
          return res;
        }),
        tap(dt => {
          console.log(dt);
        })
      );
  }

  forgot_password(body): Observable<any> {
    const url = `${this.urlAPI}/api/v1/user/forgot-password`;
    return this._http.post(url, body, { headers: new HttpHeaders({ "Authorization": "Bearer "+this.token }) })
      .pipe(
        map(res => {
        return res;
        }),
        catchError(error => this._handler.handle(error))
    );
  }


  getMyJobs(param): Observable<any> {
    const url = `${this.urlAPI}/api/v1/job/list?page=${param.page}&per_page=${param.pageSize}`; 
    return this._http.get(url, { headers: new HttpHeaders({ "Authorization": "Bearer "+this.token }) })
    .pipe(
      map(data => {
        return data;
      }),
      catchError(error => this._handler.handle(error))
    );
  }

  getFeedBack(param): Observable<any> {
    const url = `${this.urlAPI}/api/v1/feedback?user_id=${this.idUser}&page=${param.pageFeedback}&per_page=${param.pageSizeFeedback}`; 
    return this._http.get(url, { headers: new HttpHeaders({ "Authorization": "Bearer "+this.token }) })
    .pipe(
      map(data => {
        return data;
      }),
      catchError(error => this._handler.handle(error))
    );
  }

  /**
     * updateFcmId - accept Invite
     * @param body param for API
     */
   updateFcmId(body): Observable<any> {
    const url = `${environment.apiUrl}/api/v1.0/user/update-firebase/`;
    return this._http.put(url, body, { headers: new HttpHeaders({ 'Authorization': `Bearer ${this.token}` }) })
    .pipe(
        map(res => {
        return res;
        }),
        catchError(error => this._handler.handle(error))
    );
}


}
