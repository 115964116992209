import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { Injectable } from '@angular/core';
import * as EXIF from "exif-js"
import ImageRotation from 'image-rotation'
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()

export class Helpers {
  deviceInfo: any;
  constructor(
    private _router: Router, 
    private _notify: SnotifyService,
    private _detectorService: DeviceDetectorService
  ) {}

  checkDevice () {
    this.deviceInfo = this._detectorService.getDeviceInfo();
        // let device = 'SM-A115F' 
        let devices = ['SM-A115F', "iPhone9,3", 'SM-G960F']
        let isRotate = false;
        devices.forEach(element => {
            if(this._detectorService.userAgent.indexOf(element)!=-1)
                isRotate = true
        });
        if(this.deviceInfo.deviceType == 'mobile' && isRotate) 
            return true
        else
            return false
  }

  base64ToArrayBuffer (base64) {
    base64 = base64.replace(/^data\:([^\;]+)\;base64,/gmi, '');
    var binaryString = atob(base64);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }


}

