import { Injectable } from '@angular/core';
import { BaseService } from '../core/services/base.service';
import { environment } from '../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  urlAPI = environment.apiUrl;
  firebaseNotice$ = new BehaviorSubject<any>(null);
  messageNotice$ = new BehaviorSubject<any>(null);
  messageChanel$ = new BehaviorSubject<any>(null);
  profile$ = new BehaviorSubject<any>(null);

  constructor(private _base: BaseService) { }

  /**
   * getNotifications - get all notifications
   * @method GET /api/v1/user/notification?page=1&per_page=10
   * @return object
   */
  getNotifications(param): Observable<any> {
    const url = `${this.urlAPI}/api/v1/user/notification?page=${param.page}&per_page=10`;
    return this._base.get(url);
  }

  updateNotification(id): Observable<any> {
    const url = `${this.urlAPI}/api/v1/user/notification/${id}`;
    return this._base.get(url);
  }

  readAll(): Observable<any> {
    const url = `${this.urlAPI}/api/v1/user/notification/all`;
    return this._base.get(url);
  }
   getUnreadChat(): Observable<any> {
    const url = `${environment.apiUrl}/api/v1/chat?per_page=1&page=1`;
    return this._base.get(url);
  }

}
