import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HandleError } from '../../core/helpers/error-handler';
import { AppInfoService } from './appinfo.service';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'appinfo',
  templateUrl: './appinfo.html'
})
export class AppInfoComponent implements OnInit, OnChanges {

  minlength: any;
  maxlength: any;
  info;
  rates = [1, 2, 3, 4, 5];
  constructor(
    private _service: AppInfoService
  ) {
  }

  ngOnInit() {
    this.getApp()
  }

  ngOnChanges() {
  }

  getApp() {
    this._service.getMacAppInfo().subscribe(dt => {
      console.log(dt, dt['results'][0])
      this.info = dt['results'][0]
    }, error => {
      console.log(error)
    })
    // this._service.getAppInfo().subscribe(dt => {
    //   console.log(dt)
    // }, error => {
    //   console.log(error)
    // })
  }

  goToApp = () => {
    window.open("https://apps.apple.com/vn/app/jobspace-promote-your-skills/id1533620770?fbclid=IwAR1uQVlzRLvL-0HCkn7HJF9IADafFa6ms1fyFB8WqQPK3WN-TiwOLMPxaP4")
  }
}
