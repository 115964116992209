<div class="container mt-0 d-block d-sm-none">
    <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center">
            <img [src]="info?.artworkUrl60" class="py-1"/>
            <div class="info pl-1">
              <strong style="font-size: small;" class="d-block">{{info?.trackName}}</strong>
              <i class="far fa-star" *ngFor="let rate of rates"></i>  
            </div>
        </div>
        <div class="text-right">
            <button type="button" class="btn btn-success text-white px-4 py-1" (click)="goToApp()">INSTALL</button>
        </div>
    </div>    
</div>