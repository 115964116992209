import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[scrollUp]'
})
export class ScrollUpDirective {
  @Output() scrollingUpFinished = new EventEmitter<void>();

  is_bottom = false;

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    // do tracking
    // console.log('scrolled', event.target.scrollTop);
    // Listen to click events in the component
    let tracker = event.target;

    let limit = tracker.scrollHeight - tracker.clientHeight;
    console.log(event.target.scrollTop, limit);
    if (event.target.scrollTop==0) {
      console.log('top reached');
      this.is_bottom = true;
      this.scrollingUpFinished.emit();
    } else {
      this.is_bottom = false;
      // this.scrollingFinished.emit();
    }
  }
}